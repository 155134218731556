














































import { blockchainHandler } from "@/blockchain";
import { walletStore } from "@/stores/wallet-store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConnectMetamask extends Vue {
  @Prop() requiredChainId!: string;
  @Prop({ default: false }) block!: boolean;
  @Prop({ default: false }) large!: boolean;
  @Prop({ default: false }) small!: boolean;

  walletStore = walletStore;

  get networkName() {
    const { name } = blockchainHandler.getChainConfig(this.requiredChainId);
    return name;
  }
}
